:root {
    /* Typography */
    --font-h1-size: 48px;
    --font-h2-size: 32px;
    --font-h3-size: 24px;
    --font-body1-semi: 24px;
    --font-body1-medium: 24px;
    --font-body2-semi: 16px;
    --font-body2-medium: 16px;
    --font-body3-semi: 12px;
    --font-body3-medium: 12px;

    /* Brand Colors */
    --color-brand-dark: #39842A;
    --color-brand-medium: #46AC35;
    --color-brand-light: #7ECF0C;
    --color-brand-pale-01: #CDFDAE;
    --color-brand-pale-02: #F0FDAE;

    /* Light Neutrals */
    --color-light-neutral-01: #FFFFFF;
    --color-light-neutral-02: #F7F8F7;
    --color-light-neutral-03: #C9D1C7;

    /* Dark Neutrals */
    --color-dark-neutral-01: #3E423E;
    --color-dark-neutral-02: #202220;
    --color-dark-neutral-03: #0D0E0D;

    /* Support Colors */
    --color-support-positive: #39842A; /* Positive / Light mode */
    --color-support-alert: #DD301D; /* Alert / Light mode */
    --color-support-positive-dark: #7ECF0C; /* Positive / Dark mode */
    --color-support-alert-dark: #F2A49C; /* Alert / Dark mode */

    /* Engagement Colors */
    --color-engagement-gold: linear-gradient(90deg, #D5BD74 0%, #A48832 100%);
    --color-engagement-silver: linear-gradient(90deg, #C6C2B3 0%, #898267 100%);
    --color-engagement-bronze: linear-gradient(90deg, #CDB0A6 0%, #A5715F 100%);

    /* Font Weights */
    --font-weight-semi-bold: 600;
    --font-weight-medium: 500;
}

body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: var(--color-light-neutral-02); /* Default background */
    color: var(--color-dark-neutral-02); /* Default text color */
}

h1 {
    font-size: var(--font-h1-size);
    font-weight: var(--font-weight-semi-bold);
}

h2 {
    font-size: var(--font-h2-size);
    font-weight: var(--font-weight-semi-bold);
}

h3 {
    font-size: var(--font-h3-size);
    font-weight: var(--font-weight-semi-bold);
}

p, span, a {
    font-size: var(--font-body2-medium);
    font-weight: var(--font-weight-medium);
}

button {
    font-size: var(--font-body2-medium);
    font-weight: var(--font-weight-semi-bold);
    background-color: var(--color-brand-medium);
    color: var(--color-light-neutral-01);
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: var(--color-brand-dark);
}
